<template>
    <div class="page" style="position: relative;">
        <div class="flex" style="margin-top:32px;flex:1;">
            <div class="flex" style="margin-top:9px;">
                <div> 合同签署方（公司）: </div>
                <div class="addbtn" @click="addContract" v-if="auth.save">添加</div>
            </div>
            <div>
                <el-form class="private" :model="PB" :rules="rules" label-width="100px" label-suffix=":" ref="form" @submit.prevent="handleSave" :disabled='!auth.save'>
                    <div v-for="(item,index) in PB.allData" :key="index" class="flex">
                        <el-form-item label="名称" :prop="'allData.'+ index +'.name'" :rules="rules.name">
                            <el-input v-model.trim="item.name" class="width2" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="公司编码" :prop="'allData.'+ index +'.code'" :rules="rules.code">
                            <el-input v-model.trim="item.code" class="width3" maxlength="50"></el-input>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="foot-bar">
            <el-button @click="handleClose" v-if="auth.save">取消</el-button>
            <el-button @click="handleSave" type="primary" v-if="auth.save">保存</el-button>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            auth:{
                save:false
            },
            PB: {
                allData: [
                    // {name:'',code:''},
                    // {name:'2',code:''},
                    // {name:'',code:'3'},
                ],
            },

            rules: {
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                code: [
                    {
                        required: true,
                        message: "请输入公司编码",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.auth.save = this.$hasAuthFor("api/configuration/addCom"); //新增、编辑
        this.getComList();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        async getComList() {
            console.log("fetching...");
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/configuration/queryComList",
                    method: "post",
                });

                if (res.code === 2000) {
                    this.PB.allData = res.data.comList;
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning",
                });

                loading.close();
            }
        },

        async addContract() {
            try {
                await this.$refs.form.validate();
                this.PB.allData.push({
                    name: "",
                    code: "",
                    id: "",

                    // let index =this.PB.allData.length
                    // if(index>0 &&( !this.PB.allData[index-1].code ||  !this.PB.allData[index-1].name)){
                    //     if(!this.PB.allData[index-1].code){
                    //         this.$message({
                    //            message: `请填写名称`,
                    //            type: "warning",
                    //        });
                    //     }else if(!this.PB.allData[index-1].name){
                    //         this.$message({
                    //            message: `请输入公司编码`,
                    //            type: "warning",
                    //        });
                    //     }
                    // }else{
                    //     this.PB.allData.push({
                    //         name:'',code:'',id:''
                    //     })
                    // }
                });
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "添加失败",
                        type: "error",
                    });
                console.warn(reason);
            }
        },
        async handleSave() {
            const loading = this.$loading();
            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/configuration/addCom",
                    method: "post",
                    data: {
                        companyStr: JSON.stringify(this.PB.allData),
                    },
                });
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    loading.close();
                    this.getComList();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loading.close();
            }
        },
        handleClose() {
            this.$refs.form.clearValidate();
            this.getComList();
        },
    },
};
</script>
<style lang="scss" scoped>
.page {
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.flex {
    display: flex;
}
.addbtn {
    color: #169bd5;
    cursor: pointer;
    margin-left: 16px;
}
.foot-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}
</style>